.bar {
  display: flex;
  padding: 30px 0 40px;
  justify-content: space-between;

  @media screen and (max-width: 479px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  &--left {
    order: 0;
    & > * {
      margin-right: 20px;
      @media screen and (max-width: 450px) {
        margin-right: 0;
      }
    }
  }

  &--right {
    order: 2;
    justify-content: flex-end;
    & > * {
      margin-left: 20px;
      @media screen and (max-width: 450px) {
        margin-left: 0;
      }
    }
  }

  &--left,
  &--right {
    display: flex;
    flex-basis: 33.3%;
    @media screen and (max-width: 799px) {
      flex-basis: 50%;
    }

    @media screen and (max-width: 450px) {
      flex-basis: 100%;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 10px;
    }

  }

  .domains__resultCount {
    order: 1;
    margin: 0;
    font-weight: bold;
    flex-basis: 33.3%;
    text-align: center;
    @media screen and (max-width: 799px) {
      width: 100%;
      order: 3;
    }
  }

}
