@import '../../_colors.scss';

.domain {
  color: #4a4a4a;
  background: #fff;
  height: 35px;
  margin-bottom: 4px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;

  @media screen and (max-width: 499px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "domainname domainname price btn" "time time price btn";

    height: auto;
    margin-bottom: 15px;
  }

  &__data {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    flex-basis: 10.83333%;
    padding: 0 10px;
    border-right: 1px solid #F5F5F5;
    &--auctionEndDate {
      border-right: 0;
    }

    @media screen and (max-width: 499px) {
      &.domain__data--auctionCurrentBid {
        grid-area: price;
        flex-basis: 25%;
        padding-right: 20px;
      }

      &.domain__data--domainIdn {
        padding-top: 5px;
        grid-area: domainname;
        padding-bottom: 3px;
        word-break: keep-all;
        white-space: nowrap;
      }

      &.domain__data--auctionEndDate {
        grid-area: time;
        width: 100%;
        min-width: 100%;
        justify-content: flex-start;
        padding-left: 20px;
        padding-bottom: 5px;
        font-size: 14px;
        opacity: .7;
      }

      &.domain__data--button {
        grid-area: btn;
        width: 100%;
        min-width: 100%;
        padding: 0;

        .btn {
          width: 100%;
          padding: 5px 5px;
          text-align: center;
          justify-content: center;

          border-radius: 0 4px 4px 0;
        }
      }

    }

    @media screen and (max-width: 799px) {

      &:not(.domain__data--domainIdn):not(.domain__data--auctionCurrentBid):not(.domain__data--auctionEndDate):not(.domain__data--button) {
        display: none;
      }
    }

    &:first-child,
    &:last-child {
      border-right: 0;
    }

    &--align_left {
      justify-content: flex-start;
    }

    &--small {
      flex-basis: 7%;
    }

    &--extended,
    &--button{
      flex-basis: 12.5%;
    }

    &--button {
      padding-right: 0;
    }

    &--large {
      flex-basis: 25%;
    }

    &--domainIdn {

      padding-left: 20px;
      overflow: hidden;

      &:hover {

        .addToWatchlist {
          width: 37px;
          margin-left: -17px;
          opacity: 1;
        }

      }

      .addToWatchlist {
        transition: all .2s ease-in-out;
        width: 0;
        overflow: hidden;
        opacity: 0;

        .fa-heart {
          transition: color .2s ease-in-out;
        }

        &:hover {

          .fa-heart {
            color: $red;
          }
        }
      }

    }

  }

}
