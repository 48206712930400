@import '../../_colors';

.domain_header_resultCount {
  text-align: center;
  font-weight: 600;
  margin: 0 0 30px;
}

.domain_header {
  display: flex;
  background: linear-gradient(to bottom,$grey-default, fade_out($grey-default, .4) 70%, transparent 98%);
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 5px;
  position: sticky;
  top: 0px;
  @media screen and (max-width: 499px) {
    display: none;
  }
  .domain_header_column {
    flex-basis: 10.833333%;
    text-align: right;
    padding: 0 5px;

    &:first-child {
      padding-left: 20px;
      .sorter {
        justify-content: flex-end;
      }
    }

    @media screen and (max-width: 799px) {
      &:not(.domain_header_column--domainIdn):not(.domain_header_column--auctionCurrentBid):not(.domain_header_column--auctionEndDate):not(.domain_header_column--button) {
        display: none;
      }
    }

    &--small {
      flex-basis: 7%;
    }

    &--large {
      flex-basis: 25%;
      text-align: left;
    }

    &--extended,
    &--button {
      flex-basis: 12.5%;
    }

    &--empty {
      flex-basis: 8.3333333333%;
    }

    &--button {
      padding-right: 0;
    }

    .sorter {
      justify-content: flex-start;
    }

  }

  .domain-list--basic & {

    .sorter {
      font-size: 16px;
      .sorter__label {
        font-size: 16px;
      }

      .sorter__indicator {

        &--asc,
        &--desc {
          top: 2px;
        }

      }

    }

  }

}
