@import '../../_colors';

.input--range {
  margin-top: 5px;
}

.input--range__hint {
  font-size: 12px;
  text-align: center;
  padding-top: 2px;
}

.rc-slider {
  margin: 1px auto 7px;
  width: 130px;
  & + div {
    color: $grey-dark;
    span {
      font-weight: bold;
      color: darken($grey-dark,30%);
    }
    svg {
      position: relative;
      top: 1px;
    }
  }

  .rc-slider-rail {
    background: $grey-light;
  }

  .rc-slider-track {
    background: $blue;
  }

  .rc-slider-handle {
    background: $blue;
    border: 2px solid $blue-dark;
    &:active,
    &:focus {
      box-shadow: none;
    }
  }

}
