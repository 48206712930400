.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9997;
  display: flex;
  justify-content: center;
  align-items: center;

  &__backdrop {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .45);
    z-index: 9998;
  }

  &__container {
    background-color: white;
    position: relative;
    z-index: 9999;
    max-width: 850px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 90px 0 rgba(0,0,0,0.6);
  }

  &__titleBar {
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #444;
    background: #E2DFDF;
    border-bottom: 1px solid #e6e6e6;
    position: relative;
  }

  &__closeButton {
    font-size: 22px;
    color: #555;
    cursor: pointer;
    user-select: none;
    position: absolute;
    right: 18px;
    top: 12px;
    &:hover {
      opacity: .7;
    }

  }

  &__title {
  }

  &__content {
    min-width: 300px;
    padding: 10px 15px;
  }



}

.odp {
  ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}
