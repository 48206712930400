@import '../../_colors';

.header {
  padding: 20px 0;
  margin-bottom: 0;
  background: $grey-dark;

  svg {
    height: 37px;
    display: block;

    path,
    polygon {
      fill: #fff;
    }

  }

  .wrapper {
    display: flex;
    justify-content: space-between;
  }

  /**
  &:before {
    content: "BETA";
    position: absolute;
    left: -39px;
    top: 15px;
    background: #0061AA;
    transform: rotate(-45deg);
    width: 130px;
    text-align: center;
    color: #fff;
    padding: 5px;
  }
  **/
}
