@import '../../_colors';

.footer {
  padding-top: 50px;
  color: #fff;

  a {
    color: #fff;
    text-decoration: none;
  }

  &--upper {
    background: $blue-darkest;
    padding: 50px 0;
    .form {
      max-width: 330px;
      width: 98%;
    }
    @media screen and (max-width: 759px) {
      .cols--half {
        width: 100%;
      }
    }

  }
  &--lower {
    background: $blue;

    .wrapper {
      font-size: 13px;
      padding: 24px 0;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 999px) {
        flex-wrap: wrap;
        justify-content: center;
        .footer__copy {
          margin-left: 20px;
        }
      }
    }
  }

  .wrapper {
    display: flex;
  }

  &__navigation,
  &__social {
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    @media screen and (max-width: 999px) {
      width: 100%;
      justify-content: center;
      margin-top: 20px;
    }

  }

  &__social {
    &__item {
      padding: 0 8px;
      a {
        display: block;
      }
      svg.svg-inline--fa {
        height: 24px;
        width: auto;
      }
    }
  }

  &__navigation {
    &__item {
      padding: 0 10px;
      position: relative;
      &:before {
        content: '';
        height: 100%;
        width: 1px;
        background: #fff;
        left: 0;
        top: 0;
        position: absolute;
      }
      &:first-child {
        padding-left: 0;
        &:before {
          display: none;
        }
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  &_copy {
    margin: 0;
    align-self: center;
  }

  .sedo_logo {
    display: block;

    svg {
      width: 126px;
      height: auto;
      display: block;

      path {
        fill: #fff;
      }

    }

  }

}
