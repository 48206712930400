.gotop {
  position: fixed;
  z-index: 200;
  right: 20px;
  bottom: 20px;
  transition: all .4s ease-in-out;

  &--hidden {
    transition: all .8s ease-in-out;
    opacity: 0;
    bottom: -50px;
  }

  .btn--gototop {
    padding: 15px 16px;

    svg {
      margin: 0;
    }

  }

}
