@import '../../_colors.scss';

.input--text {
  border: 1px solid $grey-medium-dark;
  padding: 0 16px;
  border-radius: 2px;
  background: #fff;
  cursor: text;

  /*.filter__item--keyword & {
    input {
      width: 220px;
    }
  }*/

  &__field {
    font-size: 14px;
    line-height: 42px;
  }

  &__icon {
    font-size: 16px;
    line-height: 42px;
    path {
      fill: $grey-medium-darker;
    }
  }

  /* Applies if an icon is used */
  &--hasIcon {
    display: flex;
    height: 44px;
    align-items: center;
    .input--text__field {
      padding: 0;
    }
  }

}
