$grey-medium: #E0E0E0;
$grey-medium-dark: #CBCBCB;
$grey-dark: #444444;
$grey-light: #BEBEBE;
$grey-medium-darker: #979797;
$grey-default: #f5f5f5;

$green: #7FBB45;
$red: #C74C4C;
$yellow: #CC9900;

$blue: #0061AA;
$blue-dark: #02497F;
$blue-darkest: #32383D;

$white: #ffffff;
