@import '../../_colors';

.domain-list {

  &__box {
    background: lighten($grey-light,15%);
    border-radius: 25px;
    padding: 50px 20px;
    margin: 0 auto;
    text-align: center;
    width: 90%;
    max-width: 600px;
    font-size: 16px;

    .btn {
      display: inline-block;
      margin-top: 15px;
    }

    &--loadingResults {
      & > svg.svg-inline--fa {
        height: 60px !important;
        margin-bottom: 15px;
      }
    }
    & > svg.svg-inline--fa {
      height: 90px;
      width: auto;
    }
    p {
      margin: 0;
    }
    h4 {
      font-size: 20px;
      margin: 0 0 10px;
    }
  }

}
