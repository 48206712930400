@import '../../_colors';

.filters {
  position: relative;

  &--expert {

  }

  .btn--reset {
    position: absolute;
    right: 0;
    top: 10px;
    &__icon--start {
      path {
        fill: $red;
      }
    }
  }

  &__tabs {

    &__titles {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;

      &__item {
        padding: 10px 20px;
        margin-right: 4px;
        display: block;
        cursor: pointer;
        background: #eee;
        border-radius: 4px 4px 0 0;
        &:hover {
          background: #E0E0E0;
        }

        &--active {
          cursor: default;
          background: #E0E0E0;
        }

        &.new-label {
          width: 90px;
        }

        &.new-label:before {
          content: "+1";
          position: absolute;
          width: 25px;
          left: 156px;
          top: 4px;
          padding: 0px;
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
          background: #585858;
          color: #fff;
          text-align: center;
          font-size: 12px;
          font-weight: bold;
          border-radius: 12px;
        }
      }

    }

    &__contents {
      background: #e0e0e0;
      border-radius: 0 4px 4px 4px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 5px 20px;
      max-height: 99em;
      -webkit-transition: all .5s cubic-bezier(1, 0, 0, 1.01);
      -moz-transition: all .5s cubic-bezier(1, 0, 0, 1.01);
      -ms-transition: all .5s cubic-bezier(1, 0, 0, 1.01);
      -o-transition: all .5s cubic-bezier(1, 0, 0, 1.01);
      transition: all .5s cubic-bezier(1, 0, 0, 1.01);



    }

  }
}

.filter {

  &__item {
    position: relative;
    padding: 0 20px;
    margin: 15px 0;
    flex-grow: 1;
    @media screen and (max-width: 749px) {
      width: 50%;
      padding: 0;
      &:nth-child(2n) {
        &:before {
          display: none;
        }
      }
      &--keyword {
        width: 100%;
      }

      &--checkbox {
        label {
          text-align: center;
        }
        .input--checkbox {
          justify-content: center;
        }
      }


    }

    @media screen and (max-width: 389px) {
      width: 100%;
      &:nth-child(1n) {
        &:before {
          display: none;
        }
      }
    }

    &--tlds {
      max-width: 170px;
      width: 100%;

      @media screen and (max-width: 749px) {
        width: 50%;
        max-width: none;
        padding: 0 10%;
      }
    }

    &--range {
      label {
        text-align: center;
      }

      &.new-label:before {
        content: "NEW";
        position: absolute;
        left: 189px;
        top: -8px;
        width: 40px;
        height: 20px;
        padding: 2px;
        background: #585858;
        color: #fff;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        border-radius: 34px;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &--double {
      width: 260px;
    }

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      display: block;
      width: 1px;
      background: rgba(151, 151, 151, .4);
      height: 100%;
    }

    &:nth-child(1) {
      padding-left: 0;

      &:before {
        display: none;
      }

    }

    &__textList {
      text-overflow: ellipsis;
      font-size: 12px;
      opacity: .7;
      display: block;
      width: 100%;
    }

    .label {
      font-size: 14px;
      display: block;
    }

    &__value {
      margin-left: 5px;
    }
  }

  &__columns {
    display: flex;
    flex-wrap: wrap;
    .input {
      width: 50%;
    }
  }

}
