.intro {
  text-align: center;
  background: #61AAE1;
  padding: 15px 5px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 30px;
  display: block;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0 2px 0 rgba(0,0,0,.4);
  color: #fff;
  font-size: 17px;
  strong {
    font-weight: 600;
    font-size: 20px;
  }
}
