.sorter {
  display: flex;
  padding: 4px 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  font-size: 11px;
  width: 100%;
  transition: all .3s ease-in-out;
  background: transparent;

  &--right {
    text-align: right;
    flex-direction: row-reverse;
    .sorter__indicator {
      // margin-left: 4px;
    }
  }

  &--left {
    text-align: left;
    flex-direction: row;
    .sorter__indicator {
      // margin-right: 4px;
    }
  }

  &:hover {
    opacity: .7;
    cursor: pointer;
  }

  & + .sorter {
    margin-top: 8px;
  }

  &--active {
    &--desc {
      .sorter__indicator--desc {
        opacity: 1;
      }
    }
    &--asc {
      .sorter__indicator--asc {
        opacity: 1;
      }
    }
  }

  &__label {
    font-size: 11px;
  }

  &__indicators {
    position: relative;
    opacity: .4;
    @media screen and (max-width: 799px) {
      .domain_header_column & {
        opacity: 0;
      }
    }
  }

  &__indicator {
    font-size: 15px;

    &--asc,
    &--desc {
      position: absolute;
      left: 0;
      top: 0;
      transition: all .3s ease-in-out;
      opacity: 0;
    }

  }

}
