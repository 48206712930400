@import '../../_colors.scss';

.input--checkbox,
.input--radio
{
  font-size: 12px;
  display: flex;

  &:hover {
    opacity: .7;
    cursor: pointer;
  }

  &__description {
    margin-left: 5px;
  }

  [type="checkbox"] {
    & + i:before {
      border-radius: 2px;
    }
  }

  [type="radio"] {
    & + i:before {
      border-radius: 16px;
    }
  }

  [type] {
    position: absolute;
    left: -9999px;

    & + i {
      position: relative;
      padding-left: 16px;
      height: 16px;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 14px;
        height: 14px;
        border: 1px solid #fff;
        background: #fff;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
      }

      svg {
        transition: all .3s ease-in-out;
        position: absolute;
        left: 2px;
        top: -1px;
        height: 100%;
        width: auto;

        path {
          fill: $blue;
        }

      }

    }

    &:not(:checked) + i {
      &:before {
        border-color: $grey-medium-darker;
      }
      svg {
        opacity: 0;
        transform: scale(0);
      }
    }
    &:checked + i {
      &:before {
        border-color: $blue;
      }
      svg {
        opacity: 1;
        transform: scale(1);
      }
    }

    &:disabled:not(:checked),
    &:disabled:checked {
      & + i:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
      }
    }
    &:disabled:checked + i svg path {
      fill: #999;
    }
    &:disabled + i {
      opacity: .7;
    }

  }
}
