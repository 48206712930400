@import '../../_colors';

.btn {
  display: block;
  padding: 10px 15px;
  cursor: pointer;
  background: $grey-light;
  color: $grey-dark;
  border-radius: 4px;
  font-size: 14px;
  text-decoration: none;
  transition: all .3s ease-in-out;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    opacity: .8;
  }

  &--label {
    color: #000;
    padding: 0px 0px 5px 0px !important;
    width: 100%;
    &:hover {
      opacity: 1;
    }
    & + .dropdown__container--right {
      right: -14px;
      top: 64%;
    }
  }

  &__icon {
    &--start {
      margin-right: 8px;
    }
    &--end {
      margin-left: 10px;
    }
  }

  &--round {
    border-radius: 50px;
  }

  &--smaller {
    padding: 6px 15px;
  }

  &--black {
    background: #32383D;
    color: $white;
  }

  &--blue {
    background: $blue;
    color: $white;
  }

  &--transparent {
    background: transparent;
  }

  &--green {
    background: $green;
  }

  &--center {
    margin: 0 auto;
  }

  &--gdpr {
    padding: 15px;
    background: lighten($grey-dark,8%);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    svg {
      margin: 0;
    }
  }

  &--mode {
    @media screen and (max-width: 799px) {
      display: none;
    }
  }

  &--loadMore {
    margin-top: 25px;
    svg {
      transition: all .4s ease-in-out;
    }
    &:hover {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &--bid {
    display: flex;
    align-self: stretch;

    padding: 0 24px;
    color: #fff;
    font-weight: 600;
    text-decoration: none;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    .domain-list--expert & {
      padding: 0 15px;
    }
    span {
      align-items: center;
      display: flex;
      .domain-list--expert & {
        font-size: 14px;

      }
    }
  }


}
