@import '../../_colors.scss';

.dropdown {

  &--doubleColumns {
    button svg path {
      fill: $blue;
    }
    .dropdown__list {
      display: flex;
      flex-wrap: wrap;
      &__item {
        flex-basis: 50%;
      }
    }
  }

  &--sorting {
    display: none;
    @media screen and (max-width: 799px) {
      display: block
    }

    &--keyword .input--text input {
      width: auto;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__container {
    &--inner {
      max-height: 210px;
      overflow-y: auto;
      padding: 15px 15px 5px 10px;
    }

    &--right {
      right: 0;
      &:before {
        right: 10px;
      }
    }

    &--left {
      left: 0;
      &:before {
        left: 10px;
      }
    }

    &--outer {
      background: #fff;
      position: absolute;
      z-index: 10;
      top: 100%;
      box-shadow: 0 2px 20px 0 rgba(0,0,0,0.34);
      border-radius: 4px;
      width: 160px;
      margin-top: 12px;
      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7px 9px 7px;
        border-color: transparent transparent #fff transparent;
        top: -9px;
      }

      &:after {
        content: '';
        height: 12px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,1));
      }
    }
  }

  &__section {
    & + .dropdown__section {
      padding-top: 8px;
      margin-top: 8px;
      border-top: 1px solid $grey-medium-dark;
    }
  }


  &__title {
    margin: 0;
    padding: 0 0 8px 0;
    font-size: 12px;
    font-weight: 600;

  }

  &__list {
    list-style: none;
    margin: 0 0 5px 0;
    padding: 0;
    &__item {

    }
  }
}
