@font-face {
  font-family: 'Source Sans Pro';
  src: url('/assets/fonts/sourcesanspro/sourcesanspro-regular.woff2') format('woff2'),
  url('/assets/fonts/sourcesanspro/sourcesanspro-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('/assets/fonts/sourcesanspro/sourcesanspro-regular-italic.woff2') format('woff2'),
  url('/assets/fonts/sourcesanspro/sourcesanspro-regular-italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('/assets/fonts/sourcesanspro/sourcesanspro-semibold.woff2') format('woff2'),
  url('/assets/fonts/sourcesanspro/sourcesanspro-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('/assets/fonts/sourcesanspro/sourcesanspro-semibold-italic.woff2') format('woff2'),
  url('/assets/fonts/sourcesanspro/sourcesanspro-semibold-italic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}
