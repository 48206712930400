@import '_colors';
@import '_fonts';

body {
  margin: 0;
  padding: 0;
  background: $grey-default;
  font-size: 16px;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

input, button {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

a {
  transition: all .3s ease-in-out;
  &:hover {
    opacity: .7;
  }
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  &__content {
    flex: 1;
  }
}

.wrapper {
  max-width: 1200px;
  width: 96%;
  margin: 0 auto;
  &.domain-list {
    font-size: 16px;
    &--expert {
      max-width: 98%;
      width: 98%;
      font-size: 11px;
    }
  }
}

label {
  display: block;
  padding-bottom: 5px;
}

button {
  outline: none;
  border: 0;
}

input, textarea {
  display: block;
  padding: 0;
  margin: 0;
  outline: none;
  border: 0;
  border-radius: 2px;
  width: 100%;
  padding: 5px;
  font-size: 14px;
  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder,
  &::placeholder {
    color: $grey-dark;
  }
  &::placeholder {
    color: $grey-dark;
  }
}

textarea {
  min-height: 100px;
}

.cols {
  display: flex;
  @media screen and (max-width: 789px) {
    flex-direction: column;
    align-items: center;
  }
  &--half {
    padding: 0 25px;
    width: 50%;
    justify-content: space-around;
    @media screen and (max-width: 789px) {
      & + .cols--half {
        padding-top: 40px;
      }
    }
  }
  &--contentCentered {
    display: flex;
    justify-content: center;
  }
}

.comingSoon {
  user-select: none;

  &--rel {
    position: relative;
  }

  &:before {
    z-index: 5;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    content: '';
    opacity: 0;
    transition: all .3s ease-in-out;
  }

  &--light {
    &:before {
      background: rgba(255,255,255,.8);
    }
    &:after {
      color: #444;
    }
  }

  &--dark {
    &:before {
      background: rgba($blue-darkest,.8);
    }
    &:after {
      color: #fff;
      text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
    }
  }

  &:after {
    z-index: 6;
    content: 'Coming soon';
    position: absolute;
    font-size: 13px;
    font-weight: 600;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
    opacity: 0;
    transition: all .3s ease-in-out;
  }

  &:hover {
    &:before,
    &:after {
      opacity: 1;
    }

  }

}


abbr {
  cursor: help;
  padding: 0 2px;
  display: inline-block;
  text-decoration: none;
}
